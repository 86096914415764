import React from "react";
import Layout from "../components/Layout/layout";

const NotFound = () => {
  const browser = typeof window !== "undefined" && window;

  return (
    <>
      {browser && (
        <Layout>
          <div className="container page-not-found-container">
            <div className="page-not-found">
              <div className="text">
                <div className="header">
               We can’t find the page you’re looking for!
                </div>
                <p>Here are some useful links instead:</p>
                <div className="usefull-links">
                  <a className="yellow" href="https://www.kraftshala.com/">{">"} Kraftshala Home</a>
                  <br/>
                  <a href="https://www.kraftshala.com/marketing-launchpad/digital-marketing-course/">{">"} Digital Marketing Course</a>
                  <br/>
                  <a href="https://www.kraftshala.com/content-writing-course/">{">"} Social Media and Content Writing Course</a>
                  <br/>
                  <a href="https://www.kraftshala.com/sales-course/">{">"} PGP in Sales and Business Leadership </a>
                  <br/>
                  <a href="https://placement-reports.kraftshala.com/">{">"} Kraftshala Placement Report</a>
                </div>
              </div>
              <div className="img">
                <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1suvmte.png" alt="Page not found" className="pageNot-found-img"/>
              </div>
            
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default NotFound;
